

import { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader,IonInput, IonSpinner,IonImg, IonCardTitle,IonButton,IonLabel, IonIcon, IonCol, IonRow, IonFooter, IonModal, IonContent,    IonAccordion,    IonAccordionGroup, IonItem } from "@ionic/vue";
import { expandOutline, locationOutline, chevronDown, chevronBack,helpCircle, checkmark, close, add, remove, addCircleOutline, removeCircleOutline, heart } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { useStore } from "vuex";

import PinchScrollZoom, { PinchScrollZoomEmitData } from "@coddicat/vue3-pinch-scroll-zoom"

export default defineComponent({
  name: "ProdutosModal",
  components: { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader,IonInput, IonSpinner, IonImg, IonIcon, IonCardTitle, IonButton,IonLabel,IonCol,IonRow, IonModal,IonFooter, IonContent, IonAccordion,    IonAccordionGroup,    IonItem, PinchScrollZoom  },
  props: {
    produto: {
      type: Object,
      required: true,
      default: () => {}
    },
    info:{
      type:Boolean,
      required: false,
      default:false
    },
    show:{
      type:Boolean,
      required: true,
      default:false
    },
  },
  data() {
    return {
      open:true,
      isOpenDetalhes : this.show,
      isOpenDetalhesInfo : this.info,
      isLast: false,
      scale: { zoom:1, originX:120, draggable:false, canDismiss: true },
      img:"",
      expandOutline , locationOutline,chevronDown, helpCircle, checkmark, close, add, remove,addCircleOutline, removeCircleOutline, heart
    }
  },
  beforeUnmount() {

    const modal_produtos : any = this.$refs['modal_produtos']
    modal_produtos.$el.dismiss(null, 'cancel');

  },
  emits: ["onDismiss"],
  methods: {
    toZoom() {
      if(this.scale.zoom>1) this.scale={zoom:1, originX:120, draggable:false, canDismiss: true}
      else this.scale = {zoom:3, originX:240, draggable:true, canDismiss: false}
    },
    toShow() {
      this.img = this.produto.image+'?sw=260&sfrm=png&sm=fit&q=80'
    },
    toDismis() {
      this.$emit("onDismiss", "")
      this.open=true
      this.img=""
    },
    localMinus(produto:any) {
      if(produto.localqtd==undefined)produto.localqtd=1
      if( produto.localqtd > 0 )produto.localqtd--
    },
    localPlus(produto:any) {
      if(produto.localqtd == undefined)produto.localqtd=0
      produto.localqtd++
    },
    localParse(produto:any) {
      if(produto.localqtd == undefined)produto.localqtd=1
      return  produto.localqtd
    },
    itens(id:any) {

      const adjuste =  useStore().getters.productIncart(id);
      return adjuste || []

    }
  },
});
