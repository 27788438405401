

import { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader,IonInput, IonSpinner,IonImg, IonCardTitle,IonButton,IonLabel, IonIcon, IonCol, IonRow, IonFooter, IonModal, IonContent,    IonAccordion,    IonAccordionGroup,    IonItem, } from "@ionic/vue";
import { locationOutline, chevronDown, chevronBack,helpCircle, checkmark, close, add, remove, addCircleOutline, removeCircleOutline, heart  } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import {useStore} from "vuex";

import  ProdutoModal  from '../components/ProdutoModal.vue'

export default defineComponent({
  name: "produto",
  components: { IonThumbnail, IonSkeletonText, IonSlides, IonSlide, IonCard, IonCardHeader,IonInput, IonSpinner, IonImg, IonIcon, IonCardTitle, IonButton,IonLabel,IonCol,IonRow, IonModal,IonFooter, IonContent, IonAccordion,    IonAccordionGroup,    IonItem,  ProdutoModal},
  props: {
    produto: {
      type: Object,
      required: true,
      default: () => {}
    },
    info:{
      type:Boolean,
      required: false,
      default:false
    }
  },
  data() {
    return {
      showModal: false,
      isOpenDetalhesInfo : this.info,
      isLast: false,
      locationOutline,chevronDown, helpCircle, checkmark, close, add, remove,addCircleOutline, removeCircleOutline, heart

    }
  },
  methods: {
    showProduto() {
      this.showModal = true
    },
    hideProduto() {
      this.showModal = false
    },
    localMinus(produto:any) {
      if(produto.localqtd==undefined)produto.localqtd=1
      if( produto.localqtd > 0 )produto.localqtd--
    },
    localPlus(produto:any) {
      if(produto.localqtd == undefined)produto.localqtd=0
      produto.localqtd++
    },
    localParse(produto:any) {
      if(produto.localqtd == undefined)produto.localqtd=1
      return  produto.localqtd
    },
    itens(id:any) {

      const adjuste =  useStore().getters.productIncart(id);
      return adjuste || []

    },
  },
});
